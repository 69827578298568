import React from "react";

import Button from "../../components/atoms/Button/Button";
import Countdown from "../../components/atoms/Countdown/Countdown";

import "./NYE.scss";

const eventInfo = {
  title: "Delano Smith presents <br />Detroit Beatdown",
  subHeadline: "Freaks Come Out",
  description:
    "Friends of TROOP Brazil, Sound Noir, Stereoh and Golden Record, we have a very special NYE 2021 event planned for you with non-stop music: 6 hours of Deep Techno and Deep House curated by Detroit legend Delano Smith featuring Delano Smith, Norm Talley, Rick Wilhite, and Mike “Agent X” Clark as DETROIT BEATDOWN plus very special guest New York legend Fred P. Opening set by Golden Record resident Morgan",
  btnUrl: "https://space.stereoh.one/SCRMjqL/tropical-fright",
  btnTxt: "enter detroit beatdown",
  //   btnTxt: "sign up",
  targetBlank: true,
    isDisabled: false,
};

const NYE = () => {
  document.title = "Stereoh | Detroit Beatdown";

  return (
    <div className="single nye">
      <article className="gradient-hero">
        <div
          dangerouslySetInnerHTML={{
            __html: `
              <video
                loop
                muted
                autoplay
                playsinline
                src="/assets/events-beatdown-no-copy.mp4"
                class="video-bg"
              />,
            `,
          }}
        ></div>
      </article>
      <div className="event-info">
        <h1>
          Delano Smith presents <br />
          Detroit Beatdown
        </h1>
        <h2 className="disclaimer">{eventInfo.coming}</h2>
        <ul className="artists">
          <li>Delano Smith</li>
          <li>Norm Talley</li>
          <li>Mike "Agent X" Clark</li>
          <li>Rick Wilhite</li>
          <li>
            Special Guest Fred P<span className="mobileOnly"></span>
          </li>
        </ul>
        <div className="event-body">
          <p>{eventInfo.description}</p>
        </div>
        {/* <Countdown /> */}
        <div className="cta-container">
          <Button
            buttonUse="enter-space"
            btnUrl="https://space.stereoh.one/HT9Gp6e/saturn6-room1"
            btnTxt="enter"
            targetBlank={eventInfo.targetBlank}
            disabled={eventInfo.isDisabled}
          />
        </div>
      </div>
      {/* <RelatedItems /> */}
    </div>
  );
};

export default NYE;
